.fp-top-article-top-teaser {
  .fp-article {
    &__content {
      @include space(margin, left, 180px);
      @include space(padding, all, $spacer * 6);
      background: $color-primary;
      bottom: 100px;
      float: right;
      margin-bottom: -100px;
      position: relative;
      @media screen and (max-width: $tablet) {
        @include space(margin, left, 10%);
        @include space(margin, right, -16px);
      }
      @media screen and (max-width: $mobile) {
        @include space(margin, left, 32px);
        @include padding($spacer * 2 $spacer * 4);
        bottom: 60px;
        margin-bottom: -60px;
      }
    }

    &__catchline {
      @include space(padding, top, 0);
      color: rgba($color-white, 0.55);
      @media screen and (max-width: $mobile) {
        @include space(padding, bottom, 0);
      }
    }

    &__title {
      @include space(padding, top, $spacer * 2);
      color: $color-white;
    }
    // No main key visual
    &__no-img .fp-article {
      &__content {
        bottom: 0;
        float: initial;
        margin-bottom: 0;
        position: relative;
        @media screen and (max-width: $tablet) {
          @include space(margin, left, -16px);
          @include space(margin, right, -16px);
        }
        @media screen and (max-width: $mobile) {
          @include space(margin, left, -16px);
          @include space(margin, right, -16px);
          bottom: 0;
          margin-bottom: 0;
        }
      }
    }
  }
  // No main key visual
  .fp-article__no-img .fp-article {
    &__content {
      @include space(margin, left, 0);
      @include space(padding, all, $spacer * 6);
      bottom: 0;
      float: initial;
      margin-bottom: 0;
      position: relative;
      @media screen and (max-width: $tablet) {
        @include space(margin, left, -16px);
        @include space(margin, right, -16px);
      }
      @media screen and (max-width: $mobile) {
        @include space(margin, left, -16px);
        @include space(margin, right, -16px);
        bottom: 0;
        margin-bottom: 0;
      }
    }
  }
}

.fp-top-article-bottom-teasers {
  .fp-article {
    margin-bottom: -40px;

    &__content {
      @include space(margin, left, 24px);
      @include space(padding, horizontal, $spacer * 4);
      background: $color-white;
      bottom: 40px;
      position: relative;
      @media screen and (max-width: $mobile) {
        @include space(margin, left, 16px);
      }
    }

    // No main key visual
    &__no-img {
      margin-bottom: 0;

      .fp-article {
        &__content {
          @include space(margin, left, 0);
          background: $color-white;
          bottom: 0;
          position: relative;
          @media screen and (max-width: $mobile) {
            @include space(margin, left, 0);

          }
        }
      }
    }

    &__title {
      @include space(padding, top, $spacer * 2);
    }
  }
}
