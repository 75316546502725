.fp-most-popular-section {
  margin-left: -50px;
  margin-right: -50px;
  @media screen and (max-width: $mobile) {
    margin-left: -16px;
    margin-right: -16px;
  }
}

.fp-most-popular-articles-teasers {
  border: 0;
  border-radius: 0;
  border-top: 2px solid $color-primary;

  @media screen and (max-width: $mobile) {
    padding-left: 16px;
    padding-right: 16px;
  }

  .fp-most-popular-section-title {
    color: $color-primary;
    left: 50%;
    transform: translateX(-50%);
  }

  .fp-article-most-popular.fp-article {
    .fp-article {
      &__content {
        header {
          @include space(padding, left, $spacer * 6);
        }
      }

      &-index {
        color: $color-primary;
      }

      &__catchline {
        color: $color-primary;
      }
    }
  }
}
