// This file contains variant for the NEWSLETTER BANNER styles
// This file is imported into the _variant-sao.scss file

.fp-newsletter-banner {
  &__logo-container {
    &::before {
      background: $color-secondary;
    }
  }

  &__logo {
    span {
      @include size(127px, 40px);
    }
  }

  &__content {
    background: $color-secondary;
  }

  &__button {
    background: $color-primary;
    color: $white;
  }
}
