// Image overrides

.article-image {
  @include margins(0 auto $spacer * 10 auto);
  position: relative;

  .fp-figure {
    @include margins(0);
  }

  .fp-image-info {
    @include margins(0);
    @include padding($spacer * 2);
    background: $color-secondary;
    opacity: 0.95;
    position: initial;

    span {

      @include margins(0 0 $spacer * 2 0);
      color: $white;
      display: block;

      &:last-child {
        @include margins(0);
      }
    }
  }
}

.fp-image__caption {
  @include margins(0 0 $spacer * 2 0);
  display: inline-block;
}

.fp-image-info {
  span {

    font-size: $text-base-size;
    @media(max-width: $mobile) {
      font-size: $text-sm;
      line-height: $line-height-sm;
    }
  }
}

.fp-article-body {
  .fp-article-list {
    .list-content {
      list-style: decimal;
    }

    .list-item {
      @include margins(0 0 $spacer * 3 0);

      &:last-child {
        @include margins(0);
      }
    }

    ::marker {
      color: $color-primary;
    }
  }
}

// Article body social media overrides

.fp-article-block__social-media {
  @include flex();
  @include padding($spacer * 4 0 0 0);
  @include margins($spacer * 4 0 $spacer * 8 0);
  border-top: $border-light-gray;

  &--button {
    margin-left: $spacer * 4;


    &:first-child {
      margin-left: 0;
    }

    span {
      @include size(32px, 32px);
      @include flex(center, center, null);
      @include transition(0.2s, ease);
      background: $lighter-gray;
      border-radius: 100%;

      svg {
        @include size(18px, auto);
      }

      &:hover {
        background: $light-gray;
      }
    }
  }
}


