
.fp-blockquote {
  &__text {
    font-style: italic;

    &::before {
      @include font($spacer * 12, $font-bold, normal);
      color: $color-primary;
      content: '“';
    }
  }

  &__source {
    @media(max-width: $mobile) {
      @include margins($spacer * 2 0 0 0);
    }
  }
}
