.fp-category-name-header {
  border: 0;
  margin-left: -50px;
  margin-right: -50px;
  position: relative;
  z-index: 0;
  @media screen and (max-width: $tablet) {
    margin-left: -16px;
    margin-right: -16px;
    text-align: right;

    &::after {
      content: none;
    }
  }

  &::before {
    background: $color-primary;
    content: '';
    height: 2px;
    left: 0;
    position: absolute;
    top: 50%;
    width: 100%;
    z-index: -1;
  }

  svg {
    path {
      fill: $color-primary;
    }
  }

  &__text {
    text-transform: uppercase;
    white-space: nowrap;
    @media screen and (max-width: $mobile) {
      font-size: 20px;
    }
  }

  &__thema {
    color: $color-secondary;
    font-weight: 400;
  }

  &__content {
    @include space(padding, right, $spacer * 4);
    background: $color-white;
    display: inline-flex;
    @media screen and (max-width: $mobile) {
      @include padding(0 $spacer * 2);
    }
  }

  span {
    background: $white;
    position: relative;
  }
}
