@import '../default/variables';


// override any variable below


// Typography Variables
$font-primary: 'Poppins', sans-serif;
$font-secondary: 'Poppins', sans-serif;

// Font Size Variables
$text-xs: 12px;
$text-sm: 14px;
$text-md: 16px;
$text-lg: 18px;
$text-xl: 22px;
$text-xxl: 28px;
$text-xxxl: 32px;


// Color Variables
$red: #cb4a21;
$orange: #e5732e;
$light-blue: #4aa5d6;
$dark-blue: #0c2940;

$color-primary: $red;
$color-secondary: $dark-blue;
$color-primary-light: $orange;
$color-primary-light-2: $light-blue;
$color-text: $dark-blue;

$color-primary--50: #b6bfc6;
$color-primary--30: #b6bfc6;
$color-text--80: #556979;
$color-borders: $color-primary--30;
$color-white: #fff;

$publication-newsletter-logo: '/design/source/images/sao-logo.svg';

