.fp-article-heading {
  &__catchline {
    display: block;
    color: $color-primary;
  }

  &__title {
    @include font($text-xxxl, $weight-extraBold, normal);
    display: block;
    color: $color-text;
    line-height: $line-height-xxxl;
    @media screen and (max-width: $mobile) {
      @include font($text-xxl, $weight-extraBold, normal);
      line-height: $line-height-xxl;
    }
  }

  &__excerpt {
    color: $color-text;
    font-size: $spacer * 5 + 2;
    @media (max-width: 700px) {
      font-size: $text-md;
      line-height: $line-height-md;
    }
  }
}
