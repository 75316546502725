.fp-page-title {
  &.fp-category-name-header::before {
    content: none;
  }

  &.fp-category-name-header svg {
    display: none;
  }
  @media screen and (max-width: 900px) {
    &.fp-category-name-header {
      margin-left: 0;
      margin-right: 0;
      text-align: initial;
    }
  }
}
