@font-face {
  font-display: swap;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  src: url('/design/source/fonts/Poppins-Thin.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 100;
  src: url('/design/source/fonts/Poppins-ThinItalic.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  src: url('/design/source/fonts/Poppins-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 200;
  src: url('/design/source/fonts/Poppins-ExtraLightItalic.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: url('/design/source/fonts/Poppins-Light.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 300;
  src: url('/design/source/fonts/Poppins-LightItalic.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('/design/source/fonts/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('/design/source/fonts/Poppins-Medium.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 500;
  src: url('/design/source/fonts/Poppins-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url('/design/source/fonts/Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  src: url('/design/source/fonts/Poppins-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url('/design/source/fonts/Poppins-Bold.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  src: url('/design/source/fonts/Poppins-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  src: url('/design/source/fonts/Poppins-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 800;
  src: url('/design/source/fonts/Poppins-ExtraBoldItalic.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  src: url('/design/source/fonts/Poppins-Black.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 900;
  src: url('/design/source/fonts/Poppins-BlackItalic.ttf') format('truetype');
}
