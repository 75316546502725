
.fp-internal-teaser {
  background: $color-secondary;
  border: 0;
  margin-bottom: $spacer * 6;

  .fp-internal-teaser_header,
  &_category,
  .fp-internal-teaser_title {
    color: $white;
  }

  .fp-internal-teaser_title {
    font-size: 13px;
  }

  .fp-internal-teaser_header {
    font-size: $text-xs;
  }

  header {
    @include margins($spacer * 3 0 0 0);
  }
}
