

.fp-category-section {
  &__main-article {
    @include space(padding, bottom, 24px);
    @include space(margin, bottom, 24px);
    border-bottom: 0;

    .fp-article {
      &__content,
      &__excerpt {
        @include space(padding, all, $spacer * 6);
        @include space(padding, bottom, $spacer * 2);
        background-color: $color-primary;
        margin-left: -50px;
        margin-right: -50px;
        @media screen and (max-width: $tablet) {
          margin-left: -16px;
          margin-right: -16px;
        }
      }

      &__catchline {
        @include space(padding, top, 0);
        color: $color-white;
      }

      &__title {
        @include space(padding, top, $spacer * 2);
        color: $color-white;
      }
    }

    // No main key visual
    &.fp-article__no-img .fp-article {
      &__excerpt {
        @include space(margin, top , 0);
        @include space(padding, top , 0);
        @include space(padding, bottom , 24px);
        color: white;
      }
    }
  }
}
