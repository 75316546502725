// SAO Most popular topic

.fp-most-popular-topics {
  &__header {
    background-color: transparent;
  }

  &__list {
    &-item {
      background-color: $color-secondary;

      &:hover {
        a {
          background-color: darken($color-primary, 5%);
        }
      }
    }
  }
}

