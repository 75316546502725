.fp-paid-article {
  &__article {
    &::before {
      content: none;
    }
  }

  &__section-title {
    &::before {
      content: none;
    }
  }
}
