.fp-gallery-list {
  .fp-category-name-header {
    &::before {
      background-color: $color-primary-light-2;
    }

    svg path {
      fill: $color-primary-light-2;
    }

    &__text {
      color: $color-primary-light-2;
    }
  }


  &__badge {
    background-color: $color-white;
    bottom: 0;
    color: $color-secondary;
    left: 0;
  }

  &__main-teaser {
    .fp-gallery-list {
      &__badge {
        @include space (padding, left, $spacer * 8);
      }

      &__title {
        color: $color-primary-light-2;
      }
    }
  }
}

.fp-gallery-list__teaser:nth-child(n + 2) {
  .fp-gallery-list__badge {
    @media screen and (min-width: $mobile) {
      bottom: initial;
      left: initial;
      right: 0;
      top: 0;
    }
  }
}
