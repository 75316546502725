// This file contains all the CTA styles
// This file is imported into the _variant-sao.scss file

.fp-register-banner {
  &__content {
    @include flex(center, center, null);
    @include padding($spacer * 15);
    background-color: $dark-blue;
    position: relative;
    text-align: left;
    @media(max-width: $mobile) {
      @include padding($spacer * 6 $spacer * 6 $spacer * 15);
    }
  }

  &__button {
    @include flex(null, center, null);
    @include padding($spacer * 4, $spacer * 6);
    @include margins(0);
    background-color: $color-primary-light;
    bottom: -31px;
    color: $white;
    position: absolute;
    text-transform: capitalize;

    &:hover {
      background-color: darken($color-primary-light, 5%);

      .fp-register-banner__icon {
        @include translate($spacer, 0);
      }
    }
  }

  &__icon {
    @include flex(center, center, null);
    @include margins(0 0 0 $spacer * 2);
    @include transition(0.2s, $expo);
  }

  &__text-container {
    @include flex(null, null, column);
  }

  &__title {
    @include padding(0);
    @include size(null, null, $maxw: 600px);
    font-size: $text-xxxl;
    line-height: 1.3;
    order: 2;
    @media(max-width: $mobile) {
      font-size: $text-xxl;
    }
  }

  &__text {
    @include margins(0 0 $spacer * 4);
    font-size: $text-xxl;
    font-weight: $font-regular;
    order: 1;
    @media(max-width: $mobile) {
      font-size: $text-sm;
    }
  }

  &__logo-small {
    display: none;
  }
}

